import { Paper, Stack } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setSelectedPost } from '../../redux/slices/postSlice';
import './blog-item.scss';

const BlogItem = ({ post }) => {
	const { activeUser } = useSelector((state) => state.user);
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const handleClick = () => {
		dispatch(setSelectedPost(post));
		if (
			activeUser &&
			(activeUser?.role === 'admin' || activeUser?.role === 'superAdmin')
		) {
			navigate(`/admin/blog/post/${post?._id}`);
		} else if (!activeUser) {
			navigate(`/blog/post/${post?._id}`);
		}
	};

	const handleLoadPost = (post) => {
		dispatch(setSelectedPost(post));
	};

	return (
		<div className='pressable' onClick={handleClick}>
			<Paper className='blog-item' elevation={5}>
				<Stack
					direction='row'
					justifyContent='space-between'
					alignItems='center'
				>
					<div className='blog-vitals'>
						<div className='title-category'>
							<h3>{post?.title}</h3>
							<Stack direction='row'>
								{post?.categories?.map((cat) => (
									<h6 key={cat._id}>{cat.category}</h6>
								))}
							</Stack>
						</div>
						<div className='date-author'>
							<span className='blog-date'>
								{new Date(post?.createdAt).toDateString()}
							</span>
							<span className='blog-author'>{`Author: ${post?.author.firstName} ${post?.author?.lastName}`}</span>
						</div>
					</div>
				</Stack>
				<div className='blog-preview'>
					<div className='limited-txt'>
						<p>{post?.content}</p>
					</div>
					<a
						href={
							activeUser &&
							(activeUser?.role === 'admin' ||
								activeUser?.role === 'superAdmin')
								? `/admin/blog/post/${post?._id}`
								: !activeUser && `/blog/post/${post?._id}`
						}
						onClick={() => handleLoadPost(post)}
					>
						Read More
					</a>
				</div>
			</Paper>
		</div>
	);
};

export default BlogItem;
